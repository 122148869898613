import type {
  BankAccount,
  BankInstitution,
  BankSource,
  InsuranceLogin,
  RecentSearch,
  SearchResultItem,
  Vendor,
} from "@lassie/types";
import { LocalStorage } from "../../../lib/local-storage";
import { socket } from "../../../lib/socket-proxy";
import type { DiskAction } from "../types";

export async function handleInsuranceLogins(
  _payload: DiskAction.InsuranceLogins["options"],
): Promise<{ insuranceLogins: InsuranceLogin[] }> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    return { insuranceLogins: [] };
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "insuranceLogins",
      {
        selectedPractice,
      },
      (response) => {
        if (response.success) {
          resolve({
            insuranceLogins: response.result.insuranceLogins.sort((a, b) =>
              a.source.localeCompare(b.source),
            ),
          });
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleBankAccounts(
  _payload: DiskAction.BankAccounts["options"],
): Promise<{ bankSources: BankSource[] }> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    return { bankSources: [] };
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "bankAccounts",
      {
        selectedPractice,
      },
      (response) => {
        if (response.success) {
          resolve({
            bankSources: response.result.bankSources,
          });
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleCreateBankLink(
  payload: DiskAction.CreateBankLink["options"],
): Promise<DiskAction.CreateBankLink["response"]> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("Selected practice is required");
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "createBankLink",
      {
        vendor: payload.vendor,
        selectedPractice,
        bankSourceId: payload.bankSourceId,
      },
      (response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleCompleteBankLink(
  payload: DiskAction.CompleteBankLink["options"],
): Promise<DiskAction.CompleteBankLink["response"]> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("Selected practice is required");
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "completeBankLink",
      { ...payload, selectedPractice },
      (response) => {
        if (response.success) {
          resolve(null);
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleRemoveBankLink(
  payload: DiskAction.RemoveBankLink["options"],
): Promise<DiskAction.RemoveBankLink["response"]> {
  return new Promise((resolve, reject) => {
    socket.emit("removeBankLink", { ...payload }, (response) => {
      if (response.success) {
        resolve(null);
      } else {
        reject(response.error);
      }
    });
  });
}

export async function handleSearch(
  payload: DiskAction.Search["options"],
): Promise<{ results: SearchResultItem[]; query: string }> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!payload.query) {
    throw new Error("Query is required");
  }

  if (!selectedPractice) {
    return { results: [], query: "" };
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "search",
      { selectedPractice, query: payload.query, model: payload.model },
      (response) => {
        if (response.success) {
          resolve({ results: response.result, query: payload.query });
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleClearRecentSearch(
  payload: DiskAction.ClearRecentSearch["options"],
): Promise<DiskAction.ClearRecentSearch["response"]> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    return { success: false };
  }

  return new Promise((resolve, reject) => {
    socket.emit(
      "search:clear",
      { selectedPractice, id: payload.id },
      (response) => {
        if (response.success) {
          resolve({ success: true });
        } else {
          reject(response.error);
        }
      },
    );
  });
}

export async function handleRecentSearches(): Promise<
  DiskAction.RecentSearches["response"]
> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    return [];
  }

  return new Promise((resolve, reject) => {
    socket.emit("search:recent", { selectedPractice }, (response) => {
      if (response.success) {
        resolve(response.result);
      } else {
        reject(response.error);
      }
    });
  });
}
