import type { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalStorage } from "../lib/local-storage";
import { socket } from "../lib/socket-proxy";
import { enterSync } from "../lib/transfer";
import { diskApi } from "../store/disk/api";
import { QueryTagId } from "../store/disk/tags";

const INTERVAL = 60_000; // 60 seconds
const FROM_OFFSET = 10_000; // 10 seconds

type UsePollingOptions = {
  enabled?: boolean;
};

/**
 * Polls the server for updates.
 *
 * @param options.enabled - whether the polling is enabled. (default: `true`)
 */
function usePollingImpl({ enabled }: UsePollingOptions = { enabled: true }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let timeoutId: number | undefined;

    const id = window.setInterval(() => {
      // sync at the end of interval
      timeoutId = window.setTimeout(() => sync(dispatch), INTERVAL - 10);
    }, INTERVAL);
    return () => {
      window.clearInterval(id);
      timeoutId !== undefined && window.clearTimeout(timeoutId);
    };
  }, [enabled]);
}

async function syncImpl(dispatch: Dispatch<UnknownAction>) {
  logger.info("[usePolling] syncing");

  const from = new Date().getTime() - INTERVAL - FROM_OFFSET;
  logger.info("[usePolling] syncing from", from);

  const selectedPractice = LocalStorage.get("selectedPractice");
  const lastSyncedAt = LocalStorage.get("lastSyncedAt");

  if (!selectedPractice) {
    logger.warn("[usePolling] no selected practice");
    return;
  }

  if (!lastSyncedAt?.[selectedPractice]) {
    logger.warn("[usePolling] no last synced at for selected practice");
    return;
  }

  socket.emit(
    "sync",
    {
      selectedPractice,
      from,
    },
    async (data) => {
      if ("result" in data) {
        await enterSync(data.result, selectedPractice);

        const collectionKeys = Object.keys(data.result).filter(
          (key) =>
            // skip prefetched id collections
            key !== "prefetchedPatientIds" && key !== "prefetchedEobPaymentIds",
        );
        for (const collectionKey of collectionKeys) {
          const tags: any[] = [];

          // @ts-expect-error
          const collection = data.result?.[collectionKey];
          const isCollectionArray = Array.isArray(collection);
          const isCollectionEmpty =
            isCollectionArray && collection.length === 0;

          if (isCollectionEmpty) {
            // skip empty collections
            continue;
          }

          if (collectionKey === "patients") {
            tags.push(
              ...data.result[collectionKey].map((patient) => ({
                type: "ledger",
                id: patient.id,
              })),
            );
            // TODO invalidate ledger item tags (e.g. procedures, claims, etc)
          } else {
            tags.push({
              type: collectionKey,
              id: QueryTagId.ALL,
            });
          }

          dispatch(diskApi.util.invalidateTags(tags));
        }
      } else {
        logger.error("[usePolling] sync error", data);
      }
    },
  );
}

const sync_noop = () => {};
const usePolling_noop = () => {};

export const usePolling = IS_DEMO ? usePolling_noop : usePollingImpl;
export const sync = IS_DEMO ? sync_noop : syncImpl;
