import type { Socket } from "@lassie/types";
import * as Comlink from "comlink";
import { Timer } from "./timer";
import { syncWorker } from "./workers";

export const enterLedgers = async (
  ledgerData: Socket.LedgersResult,
  selectedPractice: string,
) => {
  const transferTimer = new Timer("transfer");

  const ledgerDataString = JSON.stringify(ledgerData);
  transferTimer.check("stringify");
  const ledgerDataBuffer = new TextEncoder().encode(ledgerDataString).buffer;

  transferTimer.check("encoding");

  const size = prettyPrintSize(ledgerDataBuffer);

  logger.info("[transfer] ledgerDataBuffer size", size);

  const result = await syncWorker.enterLedgers(
    Comlink.transfer(ledgerDataBuffer, [ledgerDataBuffer]),
    selectedPractice,
  );

  transferTimer.check(`SYNC DONE FOR ${size}`);

  transferTimer.elapsed();

  return result;
};

export const enterPayments = async (
  paymentData: Socket.PaymentsResult,
  selectedPractice: string,
) => {
  const transferTimer = new Timer("transfer");

  const paymentDataString = JSON.stringify(paymentData);
  transferTimer.check("stringify");
  const paymentDataBuffer = new TextEncoder().encode(paymentDataString).buffer;

  transferTimer.check("encoding");

  const size = prettyPrintSize(paymentDataBuffer);

  logger.info("[transfer] paymentDataBuffer size", size);

  const result = await syncWorker.enterPayments(
    Comlink.transfer(paymentDataBuffer, [paymentDataBuffer]),
    selectedPractice,
  );

  transferTimer.check(`SYNC DONE FOR ${size}`);

  transferTimer.elapsed();

  return result;
};

export const enterSync = async (
  syncData: Socket.SyncResult,
  selectedPractice: string,
) => {
  const trimmedSyncData = Object.keys(syncData).reduce(
    (acc, key) => {
      const data = syncData[key as keyof Socket.SyncResult];

      if (key === "prefetchedPatientIds" || key === "prefetchedEobPaymentIds") {
        // skip syncing prefetched collections
        return acc;
      }

      if (Array.isArray(data) && data.length > 0) {
        logger.info(
          "Updating key",
          key,
          "with data length",
          Array.isArray(data) ? data?.length : data,
        );

        // @ts-expect-error
        acc[key] = data;
        return acc;
      }

      return acc;
    },
    {} as Partial<Socket.SyncResult>,
  );

  logger.info("[transfer] trimmedSyncData", trimmedSyncData);

  if (Object.keys(trimmedSyncData).length === 0) {
    logger.info("[transfer] no sync data to enter");
    return;
  }

  const transferTimer = new Timer("transfer");

  const syncDataString = JSON.stringify(trimmedSyncData);
  transferTimer.check("stringify");
  const syncDataBuffer = new TextEncoder().encode(syncDataString).buffer;

  transferTimer.check("encoding");

  const size = prettyPrintSize(syncDataBuffer);

  logger.info("[transfer] syncDataBuffer size", size);

  const result = await syncWorker.enterSync(
    Comlink.transfer(syncDataBuffer, [syncDataBuffer]),
    selectedPractice,
  );

  transferTimer.check(`SYNC DONE FOR ${size}`);

  transferTimer.elapsed();

  return result;
};

function prettyPrintSize(buffer: ArrayBuffer): string {
  const sizeInBytes = buffer.byteLength;

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  }
  if (sizeInBytes < 1024 * 1024) {
    const sizeInKB = (sizeInBytes / 1024).toFixed(2);
    return `${sizeInKB} KB`;
  }
  const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
  return `${sizeInMB} MB`;
}
